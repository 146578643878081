import styled from 'styled-components/macro'
import { Size, sizes } from '../../theme/sizes'
import rem from 'polished/lib/helpers/rem'
import React from 'react'
import { isReactElement } from '../../lib/getChildComponents'
import { media } from '../../theme/mixins/media'

type Align = 'start' | 'center' | 'end' | 'stretch' | 'baseline'

interface BreakpointProps {
  horizontal: Size
}

interface Props extends BreakpointProps {
  horizontal: Size
  align?: Align
  justify?: 'start' | 'center' | 'end' | 'between' | 'around' | 'evenly'
  className?: string
  tablet?: BreakpointProps
  desktop?: BreakpointProps
}

const alignToValueMapping = {
  start: 'flex-start',
  end: 'flex-end',
  center: 'center',
  stretch: 'stretch',
  baseline: 'baseline',
}

const justifyToValueMapping = {
  start: 'flex-start',
  end: 'flex-end',
  center: 'center',
  between: 'space-between',
  around: 'space-around',
  evenly: 'space-evenly',
}

export const Stack: React.FC<Props> = ({
  children,
  horizontal,
  align,
  className,
  tablet,
  justify,
  desktop,
}) => {
  return (
    <Wrapper
      className={className}
      horizontal={horizontal}
      justify={justify}
      tablet={tablet}
      desktop={desktop}
    >
      {React.Children.map(children, child => {
        if (isReactElement(child)) {
          return (
            <StackItem
              horizontal={horizontal}
              tablet={tablet}
              desktop={desktop}
              align={align}
            >
              {child}
            </StackItem>
          )
        }
        return child
      })}
    </Wrapper>
  )
}

export const Wrapper = styled.div<Props>`
  display: inline-flex;
  flex-wrap: wrap;
  margin-left: ${p => p.horizontal && rem(-sizes[p.horizontal])};
  margin-top: ${p => p.horizontal && rem(-sizes[p.horizontal])};
  justify-content: ${p => p.justify && justifyToValueMapping[p.justify]};

  ${p =>
    p.tablet &&
    media.tablet`
    margin-left: ${p.tablet.horizontal && rem(-sizes[p.tablet.horizontal])};
    margin-top: ${p.tablet.horizontal && rem(-sizes[p.tablet.horizontal])};
  `}

  ${p =>
    p.desktop &&
    media.desktop`
    margin-left: ${p.desktop.horizontal && rem(-sizes[p.desktop.horizontal])};
    margin-top: ${p.desktop.horizontal && rem(-sizes[p.desktop.horizontal])};
  `}
`
const StackItem = styled.div<{
  horizontal: Size
  align?: Align
  tablet?: BreakpointProps
  desktop?: BreakpointProps
}>`
  display: inline-flex;
  margin-left: ${p => p.horizontal && rem(sizes[p.horizontal])};
  margin-top: ${p => p.horizontal && rem(sizes[p.horizontal])};
  align-items: ${p => p.align && alignToValueMapping[p.align]};

  ${p =>
    p.tablet &&
    media.tablet`
    margin-left: ${p.tablet.horizontal && rem(sizes[p.tablet.horizontal])};
    margin-top: ${p.tablet.horizontal && rem(sizes[p.tablet.horizontal])};
  `}

  ${p =>
    p.desktop &&
    media.desktop`
    margin-left: ${p.desktop.horizontal && rem(sizes[p.desktop.horizontal])};
    margin-top: ${p.desktop.horizontal && rem(sizes[p.desktop.horizontal])};
  `}
`
