import React from 'react'
import styled, { css } from 'styled-components/macro'
import { color } from '../theme/color'
import { Container } from '../components/grid/Container'
import { Link } from '../components/Link'
import { grid, sizes } from '../theme'
import { rem } from 'polished'
import { Stack } from '../components/Stack'
import { Block } from '../components/Block'
import { media } from '../theme/mixins/media'
import { MatchMedia } from '../components/MatchMedia'
import LogoLightSvg from '../images/logo-light.svg'
import ClockSvg from '../images/icon/clock.svg'
import { Text } from '../components/Text'
import { Icon } from '../components/Icon/Icon'
import { Separator } from '../components/Separator'
import WhatsAppSvg from '../images/icon/whatsapp.svg'
import MapSvg from '../images/icon/map.svg'
import { font } from '../theme/mixins/font'
import { InstagramIcon } from './InstagramIcon'
import { FacebookIcon } from './FacebookIcon'
import { config } from '../config'
import christmasFooterBgJpg from '../images/christmas/footer-bg.jpg'
import christmasFooterBgWebp from '../images/christmas/footer-bg.webp'
import whatsappPng from '../images/whatsapp.png'

export const MainFooter = () => (
  <>
    <Wrapper>
      <Container>
        <>
          <Block textAlign="center" marginBottom="size6" matchMedia="tablet">
            <LogoLightSvg />
          </Block>

          <Block textAlign="center">
            <Block inline flex align="center" marginBottom="size4">
              <MatchMedia query="tablet">
                <Icon
                  size="medium"
                  color="gray3"
                  component={<ClockSvg />}
                  marginRight="size3"
                />
              </MatchMedia>
              <Text weight="light" align="left">
                <Text as="span" nowrap>
                  Lunes: 9h a 11h y 12:30h a 18h
                  <br />
                  Martes: 10h a 17h
                  <br />
                  Miércoles: 9h a 11h y 13:30h a 18h
                  <br />
                  Jueves y viernes: 9h a 16h
                </Text>
              </Text>
            </Block>
          </Block>
        </>

        <Block textAlign="center">
          <Stack horizontal="size6" justify="center" align="center">
            <ContactLink href="https://wa.me/34699762837" target="_blank">
              <Icon
                size="medium"
                color="green"
                component={<WhatsAppSvg />}
                marginRight="size2"
              />
              699 762 837
            </ContactLink>

            <ContactLink
              href="https://goo.gl/maps/VeWQJ4q5dwJUVvFT8"
              target="_blank"
            >
              <Icon
                size="medium"
                color="green"
                component={<MapSvg />}
                marginRight="size2"
              />
              Cómo llegar
            </ContactLink>
            <InstagramIcon color="green" />
            <FacebookIcon color="green" />
          </Stack>
        </Block>

        <Separator color="gray2" />

        <Block flex justify="center">
          <Stack horizontal="size5" justify="center">
            <FooterLink to="/politica-cookies">Política de cookies</FooterLink>
            <FooterLink to="/aviso-legal">Aviso legal</FooterLink>
            <FooterLink to="/politica-privacidad">
              Política de privacidad
            </FooterLink>
          </Stack>
        </Block>
      </Container>
    </Wrapper>
    <WhatsAppButton />
  </>
)

const WhatsAppButton = styled.img.attrs({
  src: whatsappPng,
  onClick: () => {
    window.location.href = 'https://wa.me/34699762837'
  },
})`
  position: fixed;
  z-index: 10;
  right: 20px;
  bottom: 20px;
  width: 64px;
  height: 64px;
  cursor: pointer;
`

const Wrapper = styled.div`
  padding: ${rem(grid.default.gap)};
  background-color: ${color.gray1};

  &,
  * {
    color: white;
  }

  ${media.tablet`
    padding-top: ${rem(sizes.size7)};
    padding-bottom: ${rem(sizes.size7)};
  `}

  ${config.enableChristmasTheme &&
    css`
      background-image: url(${christmasFooterBgJpg});
      .webp & {
        background-image: url(${christmasFooterBgWebp});
      }
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: cover;
    `}
`

const FooterLink = styled(Link)`
  color: ${color.gray3};
  ${font.small};
`

const ContactLink = styled.a`
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: ${color.green};
  font-weight: bold;

  &:hover {
    color: white;
    path {
      fill: white;
    }
  }
`
