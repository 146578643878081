import { rem } from 'polished'
import { css } from 'styled-components/macro'

import { media } from './media'
import { typi, TypiSize, TypiValue } from '../typi'

export const font = {
  xsmall: generateResponsiveFontSize(typi.xsmall),
  small: generateResponsiveFontSize(typi.small),
  base: generateResponsiveFontSize(typi.base),
  large: generateResponsiveFontSize(typi.large),
  xlarge: generateResponsiveFontSize(typi.xlarge),
  h5: generateResponsiveFontSize(typi.h5),
  h4: generateResponsiveFontSize(typi.h4),
  h3: generateResponsiveFontSize(typi.h3),
  h2: generateResponsiveFontSize(typi.h2),
  h1: generateResponsiveFontSize(typi.h1),
}

export type FontSize = keyof typeof font

function generateResponsiveFontSize(size: TypiSize) {
  return css`
    ${generateFontSize(size.default)};

    ${size.tablet &&
      media.tablet`
      ${generateFontSize(size.tablet)}
    `};

    ${size.desktop &&
      media.desktop`
      ${generateFontSize(size.desktop)}
    `};
  `
}

function generateFontSize(value: TypiValue) {
  return css`
    font-size: ${rem(value.size)};
    line-height: ${rem(value.lineHeight)};
  `
}
