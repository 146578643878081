import * as React from 'react';

/**
 * Busca tipos de componentes hijos directos encontrados en children
 * @param children objeto children del componente
 * @param componentTypes tipos de componentes hijos que se desean buscar
 */
export function getChildComponents<T>(
  children: React.ReactNode,
  componentTypes: ComponentTypes<T>,
) {
  const childComponents = {} as ChildComponents<T>;
  const childArray = React.Children.toArray(children).filter(isReactElement);
  Object.keys(componentTypes).forEach(name => {
    const componentType = componentTypes[name];
    const childComponent = childArray.find(
      child => child.type === componentType,
    );
    if (!childComponent) {
      return;
    }
    childComponents[name] = childComponent;
  });
  return childComponents as ChildComponents<T>;
}

type ComponentTypes<T> = { [name in keyof T]: ComponentType<T[name]> };

type ComponentType<T> = React.ComponentClass<T> | React.SFC<T>;

type ChildComponents<T> = { [name in keyof T]: ChildComponent<T[name]> };

type ChildComponent<T> = React.ReactElement<
  'children' extends keyof T ? T : ChildrenProps & T
>;

interface ChildrenProps {
  children?: React.ReactNode;
}

export function isReactElement(
  child: React.ReactNode,
): child is React.ReactElement<any> {
  return typeof child === 'object';
}
