import React from 'react'

import { Icon } from '../../../components/Icon/Icon'
import LocationSvg from '../../../images/icon/location.svg'
import PhoneSvg from '../../../images/icon/phone.svg'
import WhatsappSvg from '../../../images/icon/whatsapp.svg'
import styled from 'styled-components/macro'
import { color } from '../../../theme/color'
import { sizes } from '../../../theme/sizes'
import { Text } from '../../../components/Text'
import rem from 'polished/lib/helpers/rem'
import { Container } from '../../../components/grid/Container'
import { Block } from '../../../components/Block'
import { config } from '../../../config'

export const HeaderBar = () => (
  <Block matchMedia="tablet">
    <Wrapper>
      <Container flex justify="end">
        <HeaderBarButton
          href="https://goo.gl/maps/VeWQJ4q5dwJUVvFT8"
          target="_blank"
        >
          <Icon
            size="tiny"
            color="gray3"
            component={<LocationSvg />}
            marginRight="size2"
          />
          <Text
            size="small"
            color={config.enableChristmasTheme ? 'white' : 'gray2'}
          >
            Calle Abejeras, 33 bajo, Pamplona
          </Text>
        </HeaderBarButton>

        <HeaderBarButton href="https://wa.me/34699762837" target="_blank">
          <Icon
            size="tiny"
            color="green"
            component={<WhatsappSvg />}
            marginRight="size2"
          />

          <Text size="small" color="green" weight="bold">
            699 762 837
          </Text>
        </HeaderBarButton>
      </Container>
    </Wrapper>
  </Block>
)

const Wrapper = styled.div`
  display: flex;
  height: ${rem(sizes.size7)};

  background-color: ${config.enableChristmasTheme
    ? 'rgb(111 120 130)'
    : color.gray1};
`

const HeaderBarButton = styled.a`
  display: inline-flex;
  align-items: center;
  padding-left: ${rem(sizes.size3)};
  padding-right: ${rem(sizes.size3)};
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: ${color.black};
  }
`
