import {
  MarginProps,
  marginsCss,
  VerticalMarginProps,
} from '../../theme/mixins/margins'
import styled, { css } from 'styled-components/macro'
import { Size, sizes } from '../../theme'
import { media } from '../../theme/mixins/media'
import rem from 'polished/lib/helpers/rem'

interface BreakpointProps {
  marginTop?: Size
  marginBottom?: Size
  paddingTop?: Size
  paddingBottom?: Size
}

export interface Props {
  flex?: boolean
  inline?: boolean
  textAlign?: 'center'
  relative?: Boolean

  grow?: number | boolean

  // válido sólo con flex: true
  justify?: 'start' | 'center' | 'end' | 'between' | 'around' | 'evenly'
  align?: 'start' | 'center' | 'end' | 'stretch' | 'baseline'
  flexwrap?: boolean
  direction?: 'row' | 'column'

  tablet?: BreakpointProps
  desktop?: BreakpointProps
  desktopLarge?: BreakpointProps
  desktopHuge?: BreakpointProps
  matchMedia?: 'tablet' | 'desktop' | 'upToDesktop' | 'upToTablet'
}

const justifyToValueMapping = {
  start: 'flex-start',
  end: 'flex-end',
  center: 'center',
  between: 'space-between',
  around: 'space-around',
  evenly: 'space-evenly',
}

const alignToValueMapping = {
  start: 'flex-start',
  end: 'flex-end',
  center: 'center',
  stretch: 'stretch',
  baseline: 'baseline',
}

export const Block = styled.div<Props & MarginProps>`
  position: ${p => p.relative && 'relative'};
  display: ${p => p.inline && 'inline-block'};
  text-align: ${p => p.textAlign};

  ${marginsCss};

  flex-grow: ${p => {
    switch (typeof p.grow) {
      case 'boolean':
        return p.grow ? 1 : null
      case 'number':
        return p.grow
      default:
        return null
    }
  }};

  ${p =>
    p.flex &&
    css`
      display: ${p.inline ? 'inline-flex' : 'flex'};
      align-items: ${p.align && alignToValueMapping[p.align]};
      justify-content: ${p.justify && justifyToValueMapping[p.justify]};
      flex-wrap: ${p.flexwrap && 'wrap'};
      flex-direction: ${p.direction && p.direction};
    `};

  ${p => p.tablet && media.tablet`${breakpointCss(p.tablet)}`}
  ${p => p.desktop && media.desktop`${breakpointCss(p.desktop)}`}
  ${p => p.desktopLarge && media.desktopLarge`${breakpointCss(p.desktopLarge)}`}
  ${p => p.desktopHuge && media.desktopHuge`${breakpointCss(p.desktopHuge)}`}

  ${p =>
    p.matchMedia === 'tablet' &&
    css`
      ${media.onlyMobile`display: none`}
    `}

    ${p =>
      p.matchMedia === 'upToTablet' &&
      css`
        ${media.tablet`display: none`}
      `}

  ${p =>
    p.matchMedia === 'desktop' &&
    css`
      ${media.upToDesktop`display: none`}
    `}

  ${p =>
    p.matchMedia === 'upToDesktop' &&
    css`
      ${media.desktop`display: none`}
    `}
`

function breakpointCss(props: BreakpointProps) {
  return css`
    margin-top: ${props.marginTop && rem(sizes[props.marginTop!])};
    margin-bottom: ${props.marginBottom && rem(sizes[props.marginBottom!])};
    padding-top: ${props.paddingTop && rem(sizes[props.paddingTop!])};
    padding-bottom: ${props.paddingBottom && rem(sizes[props.paddingBottom!])};
  `
}
