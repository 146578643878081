import * as React from 'react'

import {
  fontFamily,
  fontWeight,
  FontWeight,
  FontFamily,
} from '../../theme/typi'
import { Color, color } from '../../theme/color'
import { marginsCss, MarginProps } from '../../theme/mixins/margins'
import styled from 'styled-components/macro'
import { font, FontSize } from '../../theme/mixins/font'
import { media } from '../../theme/mixins/media'

interface BreakpointsProps {
  size?: FontSize
}

interface Props extends BreakpointsProps {
  className?: string
  tag?: 'span' | 'p'
  color?: Color
  weight?: FontWeight
  nowrap?: boolean
  uppercase?: boolean
  family?: FontFamily
  center?: boolean
  tablet?: BreakpointsProps
  onClick?: (event: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => void
  grow?: boolean
  align?: 'left' | 'center'
}

const TextRaw: React.FC<Props> = ({ tag, className, children, onClick }) => {
  if (!children) {
    return null
  }

  return React.createElement(tag || 'p', { children, className, onClick })
}

export const Text = styled(TextRaw)<Props & MarginProps>`
  ${marginsCss};

  flex-grow: ${p => p.grow && '1'};

  ${p => p.size && font[p.size]}
  font-family: ${p => (p.family ? fontFamily[p.family] : fontFamily.roboto)};
  font-weight: ${p => p.weight && fontWeight[p.weight]};
  color: ${p => p.color && color[p.color]};

  white-space: ${p => p.nowrap && 'nowrap'};
  text-transform: ${p => p.uppercase && 'uppercase'};
  text-align: ${p => p.center && 'center'};
  text-align: ${p => p.align};

  ${p =>
    p.tablet &&
    media.tablet`
    ${p.tablet.size && font[p.tablet.size]};
  `}
`
