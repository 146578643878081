import styled from 'styled-components/macro'
import { media } from '../../theme/mixins/media'
import rem from 'polished/lib/helpers/rem'
import { Block } from '../Block'

export const Container = styled(Block)`
  margin-left: auto;
  margin-right: auto;

  ${media.tablet`
    width: ${rem(720)};
  `}

  ${media.desktop`
    width: ${rem(960)};
  `}

  ${media.desktopLarge`
    width: ${rem(1140)};
  `}

  ${media.desktopHuge`
    width: ${rem(1312)};
  `}
`
