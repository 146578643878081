import styled from 'styled-components/macro'
import { media } from '../../theme/mixins/media'
import { grid } from '../../theme'
import { rem } from 'polished'

export const MobileContent = styled.div`
  ${media.onlyMobile`
    margin-left: ${rem(grid.default.gap)};
    margin-right: ${rem(grid.default.gap)};
  `}
`
