import { css } from 'styled-components/macro'

import { breakpoints } from '../breakpoints'

export const media = {
  onlyMobile: (strings: TemplateStringsArray, ...args: any[]) => css`
    @media (max-width: ${breakpoints.tablet - 1}px) {
      ${css(strings, ...args)};
    }
  `,
  tablet: (strings: TemplateStringsArray, ...args: any[]) => css`
    @media (min-width: ${breakpoints.tablet}px) {
      ${css(strings, ...args)};
    }
  `,
  upToDesktop: (strings: TemplateStringsArray, ...args: any[]) => css`
    @media (max-width: ${breakpoints.desktop - 1}px) {
      ${css(strings, ...args)};
    }
  `,
  desktop: (strings: TemplateStringsArray, ...args: any[]) => css`
    @media (min-width: ${breakpoints.desktop}px) {
      ${css(strings, ...args)};
    }
  `,
  desktopLarge: (strings: TemplateStringsArray, ...args: any[]) => css`
    @media (min-width: ${breakpoints.desktopLarge}px) {
      ${css(strings, ...args)};
    }
  `,
  desktopHuge: (strings: TemplateStringsArray, ...args: any[]) => css`
    @media (min-width: ${breakpoints.desktopHuge}px) {
      ${css(strings, ...args)};
    }
  `,
}
