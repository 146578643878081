import React from 'react'
import { IconButtonLink } from '../components/IconButtonLink'
import FacebookSvg from '../images/icon/facebook.svg'
import { Color } from '../theme'

interface Props {
  big?: boolean
  color?: Color
}

export const FacebookIcon: React.FC<Props> = ({ big, color }) => (
  <IconButtonLink
    icon={<FacebookSvg />}
    href="https://www.facebook.com/centrofeeling"
    big={big}
    color={color}
  />
)
