import React from 'react'
import { Text } from '../../../components/Text'
import { Stack } from '../../../components/Stack'
import styled from 'styled-components/macro'
import { color } from '../../../theme/color'
import { Link } from 'gatsby'

export const MainMenuDesktop = () => (
  <Stack horizontal="size4" desktop={{ horizontal: 'size5' }}>
    <MenuOption to="/que-tratamos">
      <Text size="small" weight="bold" uppercase>
        ¿Qué tratamos?
      </Text>
    </MenuOption>
    <MenuOption to="/#tecnicas">
      <Text size="small" weight="bold" uppercase>
        Métodos y técnicas
      </Text>
    </MenuOption>
    <MenuOption to="/#tarifa">
      <Text size="small" weight="bold" uppercase>
        Tarifa
      </Text>
    </MenuOption>
    <MenuOption to="/codes-cestau-apesteguia">
      <Text size="small" weight="bold" uppercase>
        Quién soy
      </Text>
    </MenuOption>
    <MenuOption to="/contacto">
      <Text size="small" weight="bold" uppercase>
        Contacto
      </Text>
    </MenuOption>
  </Stack>
)

const MenuOption = styled(Link)`
  display: inline-block;
  position: relative;
  text-decoration: none;
  color: ${color.black};

  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    height: 4px;
    width: 0px;

    border-radius: 999999px;
    background-color: ${color.green};
    transition: width 0.3s;
  }

  &[aria-current='page'],
  &:hover {
    &::before {
      width: 100%;
    }
  }
`
