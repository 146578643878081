import React, { useState, useEffect } from 'react'
import { MobileContent } from '../../components/grid/MobileContent'
import { Block } from '../../components/Block'
import { IconButton } from './MainHero/IconButton'
import { Icon } from '../../components/Icon/Icon'
import { MainMenuMobile } from './MainHero/MainMenuMobile'
import styled from 'styled-components'
import MenuSvg from '../../images/icon/menu.svg'
import { rem } from 'polished'
import { Link } from 'gatsby'
import NormalLogoSvg from '../../images/logo.svg'
import ChristmasLogoSvg from '../../images/christmas/logo.svg'
import { media } from '../../theme/mixins/media'
import { config } from '../../config'

export const MobileHeaderBar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false)

  return (
    <>
      <TopHeaderBar onRequestOpen={() => setMenuOpen(!isMenuOpen)} />
      <FloatHeaderBar onRequestOpen={() => setMenuOpen(!isMenuOpen)} />
      <MainMenuMobile
        isOpen={isMenuOpen}
        onRequestClose={() => setMenuOpen(false)}
      />
    </>
  )
}

const FloatHeaderBar: React.FC<{ onRequestOpen: () => void }> = ({
  onRequestOpen,
}) => {
  let [lastWindowYOffset, setPos] = useState(
    (typeof window !== 'undefined' && window.pageYOffset) || 0,
  )
  let [isMenuVisible, setMenuVisible] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      let currentWindowYOffset = window.pageYOffset

      setMenuVisible(
        lastWindowYOffset > currentWindowYOffset && currentWindowYOffset > 50,
      )
      setPos(currentWindowYOffset)
    }
    typeof window !== 'undefined' &&
      window.addEventListener('scroll', handleScroll)
    return () => {
      typeof window !== 'undefined' &&
        window.removeEventListener('scroll', handleScroll)
    }
  })

  return (
    <FloatHeaderBarWrapper isVisible={isMenuVisible}>
      <MobileContent>
        <Block flex paddingTop="size4" paddingBottom="size4" justify="end">
          <IconButton onClick={onRequestOpen}>
            <Icon size="medium" component={<MenuSvg />} />
          </IconButton>
        </Block>
      </MobileContent>
    </FloatHeaderBarWrapper>
  )
}

const FloatHeaderBarWrapper = styled.div<{ isVisible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 50;

  opacity: ${p => (p.isVisible ? 1 : 0)};
  visibility: ${p => (p.isVisible ? 'visible' : 'hidden')};

  transition: opacity 0.3s;
`

const TopHeaderBar: React.FC<{ onRequestOpen: () => void }> = ({
  onRequestOpen,
}) => {
  return (
    <>
      <MobileContent>
        <Block flex paddingTop="size4" paddingBottom="size4">
          <Link to="/">
            <Logo />
          </Link>
          <Block grow flex justify="end" align="start">
            <IconButton onClick={onRequestOpen}>
              <Icon size="medium" component={<MenuSvg />} />
            </IconButton>
          </Block>
        </Block>
      </MobileContent>
    </>
  )
}

const Logo = styled(
  config.enableChristmasTheme ? ChristmasLogoSvg : NormalLogoSvg,
)`
  width: ${rem(305 / 2)};
  height: ${rem(154 / 2)};
`
