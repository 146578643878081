import * as React from 'react'
import Media from 'react-media'
import { breakpoints } from '../../theme/breakpoints'

type MediaQuery = keyof typeof mediaQueries

interface Props {
  query: MediaQuery
}

export const MatchMedia: React.FunctionComponent<Props> = ({
  query,
  children,
}) => {
  return (
    <Media query={mediaQueries[query]} defaultMatches={query === 'upToTablet'}>
      {children}
    </Media>
  )
}

const mediaQueries = {
  upToTablet: { maxWidth: breakpoints.tablet - 1 },
  tablet: { minWidth: breakpoints.tablet },
  upToDesktop: { maxWidth: breakpoints.desktop - 1 },
  desktop: { minWidth: breakpoints.desktop },
  desktopLarge: { minWidth: breakpoints.desktopLarge },
  desktopHuge: { minWidth: breakpoints.desktopHuge },
}
