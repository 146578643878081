import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { color } from '../../theme/color'
import styled from 'styled-components/macro'
import RightArrowSvg from '../../images/icon/right-arrow.svg'
import { rem } from 'polished'
import { sizes } from '../../theme/sizes'

interface Props {
  to?: string
  children: React.ReactNode
  rightArrow?: boolean
  leftArrow?: boolean
  className?: string
  component?: any | undefined
  href?: string
  target?: string
}

export const Link: React.FC<Props> = ({
  children,
  rightArrow,
  leftArrow,
  component,
  ...props
}) => (
  <CustomLink as={component} {...props}>
    {leftArrow && <LeftArrow />}
    {children}
    {rightArrow && <RightArrow />}
  </CustomLink>
)

const CustomLink = styled(GatsbyLink)`
  display: inline-flex;
  align-items: center;

  color: ${color.black};
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    path {
      fill: ${color.turquoise};
    }
  }
`

const RightArrow = styled(RightArrowSvg)`
  width: ${rem(43)};
  height: ${rem(13)};
  margin-left: ${rem(sizes.size3)};

  path {
    transition: all 0.3s;
  }
`

const LeftArrow = styled(RightArrowSvg)`
  width: ${rem(43)};
  height: ${rem(13)};
  margin-right: ${rem(sizes.size3)};
  transform: rotate(180deg);

  path {
    transition: all 0.3s;
  }
`
