import React from 'react'
import styled, { css } from 'styled-components/macro'
import { grid, sizes, color } from '../../../theme'
import { rem } from 'polished'
import { Icon } from '../../../components/Icon/Icon'
import CrossSvg from '../../../images/icon/cross.svg'
import { Block } from '../../../components/Block'
import { Link } from 'gatsby'
import ShortRightArrowSvg from '../../../images/icon/short-right-arrow.svg'
import { Text } from '../../../components/Text'
import { IconButton } from './IconButton'
import usePortal from 'react-useportal'
import { InstagramIcon } from '../../InstagramIcon'
import { FacebookIcon } from '../../FacebookIcon'
import { media } from '../../../theme/mixins/media'

interface Props {
  isOpen: boolean
  onRequestClose: () => void
}

export const MainMenuMobile: React.FC<Props> = ({
  onRequestClose,
  ...props
}) => {
  return (
    <Wrapper {...props}>
      <Block flex direction="column" justify="end">
        <Block flex align="center" marginBottom="size5">
          <Block flex grow marginLeft="size5" marginTop="size2" align="center">
            <div>Sígueme en</div>
            <Block marginLeft="size4" marginRight="size4">
              <InstagramIcon />
            </Block>
            <FacebookIcon />
          </Block>
          <CloseWrapper>
            <IconButton>
              <Icon
                size="medium"
                component={<CrossSvg />}
                onClick={() => onRequestClose()}
              />
            </IconButton>
          </CloseWrapper>
        </Block>

        <MenuOption to="/" onClick={onRequestClose}>
          <Text as="div" grow uppercase>
            Inicio
          </Text>
          <Icon size="medium" component={<ShortRightArrowSvg />} />
        </MenuOption>

        <MenuOption to="/que-tratamos" onClick={onRequestClose}>
          <Text as="div" grow uppercase>
            ¿Qué tratamos?
          </Text>
          <Icon size="medium" component={<ShortRightArrowSvg />} />
        </MenuOption>

        <MenuOption to="/#tecnicas" onClick={onRequestClose}>
          <Text as="div" grow uppercase>
            Métodos y técnicas
          </Text>
          <Icon size="medium" component={<ShortRightArrowSvg />} />
        </MenuOption>

        <MenuOption to="/#tarifa" onClick={onRequestClose}>
          <Text as="div" grow uppercase>
            Tarifa
          </Text>
          <Icon size="medium" component={<ShortRightArrowSvg />} />
        </MenuOption>

        <MenuOption to="/codes-cestau-apesteguia" onClick={onRequestClose}>
          <Text as="div" grow uppercase>
            Quién soy
          </Text>
          <Icon size="medium" component={<ShortRightArrowSvg />} />
        </MenuOption>

        <MenuOption to="/contacto" onClick={onRequestClose}>
          <Text as="div" grow uppercase>
            Contacto
          </Text>
          <Icon size="medium" component={<ShortRightArrowSvg />} />
        </MenuOption>
      </Block>
    </Wrapper>
  )
}

const Wrapper = styled.div<Partial<Props>>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding-top: ${rem(sizes.size4)};
  max-height: 100%;
  overflow-y: auto;

  z-index: 60;

  background-color: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

  transform: translateY(-110%);
  transition: transform 0.3s;

  ${p =>
    p.isOpen &&
    css`
      transform: translateY(0);
    `}

  ${media.tablet`
    display: none;
  `}
`

const MenuOption = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${color.text};
  padding: ${rem(sizes.size5)};

  border-top: 1px solid ${color.gray3};

  transition: all 0.3s;

  &:hover {
    background-color: ${color.turquoise};
    color: white;
  }
`
const CloseWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: ${rem(grid.default.gap)};
  margin-right: ${rem(grid.default.gap)};
`
