export const sizes = {
  none: 0,

  /** 4px */
  size1: 4,

  /** 8px */
  size2: 8,

  /** 12px */
  size3: 12,

  /** 16px */
  size4: 16,

  /** 24px */
  size5: 24,

  /** 32px */
  size6: 32,

  /** 48px */
  size7: 48,

  /** 64px */
  size8: 64,

  /** 96px */
  size9: 96,
}

export type Size = keyof typeof sizes
