import React from 'react'
import styled from 'styled-components'
import { Icon } from './Icon/Icon'
import { color, Color } from '../theme'
import { rem, mix } from 'polished'

interface Props {
  icon: React.ReactNode
  href: string
  big?: boolean
  color?: Color
}

export const IconButtonLink: React.FC<Props> = ({ icon, href, big, color }) => (
  <Wrapper big={big} href={href} color={color} target="_blank">
    <Icon size={big ? 'medium' : 'tiny'} component={icon} />
  </Wrapper>
)

const Wrapper = styled.a<{ big?: boolean; color?: Color }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${p => rem(p.big ? 48 : 32)};
  height: ${p => rem(p.big ? 48 : 32)};

  border-radius: 999999px;
  background-color: ${p => (p.color ? color[p.color] : color.turquoise)};
  text-decoration: none;

  transition: all 0.3s;

  path {
    fill: ${p => (p.color === 'green' ? color.text : 'white')};
  }

  &:hover {
    background-color: ${p =>
      mix(0.2, color.black, p.color ? color[p.color] : color.turquoise)};
  }
`
