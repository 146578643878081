export const color = {
  black: '#000',
  white: '#fff',

  /** #2E3639 */
  gray1: '#2E3639',

  /** #999 */
  gray2: '#999',

  /** #DDDDDD */
  gray3: '#DDDDDD',

  /** #F2F2F2 */
  gray4: '#F2F2F2',

  /** #666 */
  gray5: '#666',

  /** #B5CD46 */
  green: '#B5CD46',

  /** #0DAEC0 */
  turquoise: '#0DAEC0',

  /** #222222 */
  text: '#222222',
}

export type Color = keyof typeof color
