import { css } from 'styled-components/macro'

import { Size, sizes } from '../sizes'
import rem from 'polished/lib/helpers/rem'

export interface HorizontalMarginProps {
  marginRight?: Size
  marginLeft?: Size
  paddingRight?: Size
  paddingLeft?: Size
}

export interface VerticalMarginProps {
  marginTop?: Size
  marginBottom?: Size
  paddingTop?: Size
  paddingBottom?: Size
}

export const horizontalMarginsCss = css`
  margin-right: ${(p: HorizontalMarginProps) =>
    p.marginRight && rem(sizes[p.marginRight])};

  margin-left: ${(p: HorizontalMarginProps) =>
    p.marginLeft && rem(sizes[p.marginLeft!])};

  padding-right: ${(p: HorizontalMarginProps) =>
    p.paddingRight && rem(sizes[p.paddingRight])};

  padding-left: ${(p: HorizontalMarginProps) =>
    p.paddingLeft && rem(sizes[p.paddingLeft!])};
`

export const verticalMarginsCss = css`
  margin-top: ${(p: VerticalMarginProps) =>
    p.marginTop && rem(sizes[p.marginTop!])};

  margin-bottom: ${(p: VerticalMarginProps) =>
    p.marginBottom && rem(sizes[p.marginBottom!])};

  padding-top: ${(p: VerticalMarginProps) =>
    p.paddingTop && rem(sizes[p.paddingTop!])};

  padding-bottom: ${(p: VerticalMarginProps) =>
    p.paddingBottom && rem(sizes[p.paddingBottom!])};
`
export type MarginProps = HorizontalMarginProps & VerticalMarginProps

export const marginsCss = css`
  ${horizontalMarginsCss};
  ${verticalMarginsCss};
`
