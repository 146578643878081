import React from 'react'
import { IconSize, iconSize } from '../../theme/icon'
import styled from 'styled-components/macro'
import rem from 'polished/lib/helpers/rem'
import { Color, color } from '../../theme/color'
import { MarginProps, marginsCss } from '../../theme/mixins/margins'
import { media } from '../../theme/mixins/media'

interface BreakpointProps {
  size: IconSize
}

interface Props extends BreakpointProps {
  component: React.ReactNode
  className?: string
  color?: Color
  tablet?: BreakpointProps
  onClick?: (event: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => void
}

const IconRaw: React.FC<Props & MarginProps> = ({
  className,
  component,
  onClick,
}) => (
  <span className={className} onClick={onClick}>
    {component}
  </span>
)

export const Icon = styled(IconRaw)`
  ${marginsCss};

  display: inline-block;
  line-height: 0;

  > svg {
    width: ${p => rem(iconSize[p.size])};
    height: ${p => rem(iconSize[p.size])};

    ${p =>
      p.tablet &&
      media.tablet`
      width: ${rem(iconSize[p.tablet.size])};
      height: ${rem(iconSize[p.tablet.size])};
    `}

    path {
      fill: ${p => p.color && `${color[p.color]} !important`};
    }
  }
`
