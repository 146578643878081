import React from 'react'
import { MainFooter } from '../partials/MainFooter'
import { Block } from '../components/Block'
import { HeaderBar } from '../partials/index/MainHero/HeaderBar'

import NormalLogoSvg from '../images/logo.svg'
import ChristmasLogoSvg from '../images/christmas/logo.svg'
import rem from 'polished/lib/helpers/rem'
import styled from 'styled-components/macro'
import { Container } from '../components/grid/Container'
import { MainMenuDesktop } from '../partials/index/MainHero/MainMenuDesktop'
import { media } from '../theme/mixins/media'
import { Link } from 'gatsby'
import { MobileHeaderBar } from '../partials/index/MobileHeaderBar'
import { GlobalStyles } from '../theme/GlobalStyles'
import { config } from '../config'

interface Props {
  fullWidth?: boolean
}

export const Layout: React.FC<Props> = ({ children, fullWidth }) => (
  <>
    <GlobalStyles />
    <Wrapper>
      <HeaderBar />

      <Block matchMedia="upToTablet">
        <MobileHeaderBar />
      </Block>

      <Container>
        <Block
          flex
          marginTop="size6"
          marginBottom="size6"
          align="start"
          matchMedia="tablet"
        >
          <Link to="/">
            <Logo />
          </Link>
          <Block flex grow justify="end">
            <MainMenuDesktop />
          </Block>
        </Block>
      </Container>

      <Block grow marginTop="size4" tablet={{ marginTop: 'size1' }}>
        {fullWidth ? children : <Container>{children}</Container>}
      </Block>
      <MainFooter />
    </Wrapper>
  </>
)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const Logo = styled(
  config.enableChristmasTheme ? ChristmasLogoSvg : NormalLogoSvg,
)`
  width: ${rem(305 / 2.5)};
  height: ${rem(154 / 2.5)};

  ${media.tablet`
    width: ${rem(305 / 2.2)};
    height: ${rem(154 / 2.2)};
  `}

  ${media.desktop`
    width: ${rem(305 / 1.6)};
    height: ${rem(154 / 1.6)};
  `}
`
