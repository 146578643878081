import { createGlobalStyle } from 'styled-components/macro'

import { reboot } from './reboot'
import { reset } from './reset'
import { sizes } from '../sizes'
import { rem } from 'polished'

export { GlobalStyles }

const GlobalStyles = createGlobalStyle`
  ${reset};
  ${reboot};
`
