import styled from 'styled-components/macro'
import rem from 'polished/lib/helpers/rem'
import { sizes } from '../theme/sizes'
import { color, Color } from '../theme/color'

interface Props {
  color?: Color
}

export const Separator = styled.div<Props>`
  display: flex;
  justify-content: center;

  &::before {
    content: '';
    display: inline-block;
    width: ${rem(sizes.size7)};
    height: 4px;
    margin: ${rem(sizes.size6)} 0;
    background-color: ${p => (p.color ? color[p.color] : color.gray3)};
    border-radius: 1px;
  }
`
