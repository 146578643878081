import React from 'react'
import { IconButtonLink } from '../components/IconButtonLink'
import InstagramSvg from '../images/icon/instagram.svg'
import { Color } from '../theme'

interface Props {
  big?: boolean
  color?: Color
}

export const InstagramIcon: React.FC<Props> = ({ big, color }) => (
  <IconButtonLink
    icon={<InstagramSvg />}
    href="https://www.instagram.com/codes_fisio.osteopata/"
    big={big}
    color={color}
  />
)
