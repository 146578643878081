export enum breakpoints {
  mobileSmall = 576,
  tablet = 768,
  desktop = 992,
  desktopLarge = 1200,
  desktopHuge = 1440,
}

// export const viewport = {
//   matches: {
//     tablet: (): boolean => {
//       return breakpointQuery(breakpoints.tablet)
//     },
//     desktop: (): boolean => {
//       return breakpointQuery(breakpoints.desktop)
//     },
//   },
// }

// const breakpointQuery = (breakpoint: breakpoints) => {
//   return matchMedia(`(min-width: ${breakpoint}px)`).matches
// }
